module controllers {
    export module costing {
        interface ICostModelUpdateScope extends ng.IScope {
        }

        interface ICostModelUpdateParams extends ng.ui.IStateParamsService {
            cosId: number;
        }

        export class CostModelUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'costModelService',
                'costModelElementService'
            ];

            selectedRow: number = 0;
            cosId: number;
            costModel : interfaces.costing.CostModelDTO;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            selectedTab: number = 0;

            costModelSundry : interfaces.costing.ICostModelSundry;

            apiSundryItemCosting: uiGrid.IGridApi;
           
            constructor(
                private $scope: ICostModelUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ICostModelUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private CostModelService: interfaces.costing.ICostModelService,
                private costModelElementService: interfaces.costing.ICostModelElementService 
            ) {

                this.cosId = $stateParams.cosId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadCostModel());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'costmodel.update'
                },
                    () => {                        
                        return $q.all(loadPromises);
                    });


            }

            loadCostModel()
            {
                return this.CostModelService.getCostModelDetail().get({ cosId: this.cosId }, (data: interfaces.costing.CostModelDTO) => {

                    this.costModel = data;
                    
                    if (this.cosId === 0)
                    {
                        this.breadCrumbDesc = "Create New";
                    }
                    else
                    {
                        this.breadCrumbDesc = this.costModel.Code + " - " + this.costModel.Description;
                    }

                    this.isLoadingDetail = true;
                    this.$timeout(() => {
                        this.gvwSundryItemCosting.data = this.costModel.CostModelSundryList;
                       
                        this.isLoadingDetail = false;
                    });


                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }


            SaveCostModel(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                var _CostModelSundryList = this.costModel.CostModelSundryList;

                if (this.apiSundryItemCosting) {
                    this.costModel.CostModelSundryList = _.map(this.apiSundryItemCosting.rowEdit.getDirtyRows(this.apiSundryItemCosting.grid), (o) => { return o.entity });
                }
                else {
                    this.costModel.CostModelSundryList = [];
                }


                this.CostModelService.saveCostModel().save(this.costModel, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    if (data.HasErrorMessage)
                    {
                        deferre.resolve(false);
                        return deferre.promise;
                    }

                    if (this.apiSundryItemCosting) {
                        this.apiSundryItemCosting.rowEdit.setRowsClean(this.costModel.CostModelSundryList);
                    }

                    this.cosId = Number(data.ID);
                                  
                                    
                    deferre.resolve(true);
                }, (errorResponse) => {
                    
                    if (this.cosId === 0)
                    {
                        this.costModel.CostModelSundryList = _CostModelSundryList;
                        this.gvwSundryItemCosting.data = this.costModel.CostModelSundryList;
                    }

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };


            Save(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'costmodel.update'
                },
                    () => {
                        return this.SaveCostModel().then((data: boolean) => {
                            if (data) {
                                this.loadCostModel();
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.CostModels.Update", { cosId: this.cosId});
                            }
                        });
                    });
            }

            Close(){
                this.$state.go("^");
            }

            SaveClose(){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'costmodel.update'
                },
                    () => {
                        return this.SaveCostModel().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            loadCostModelTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CostingModelType
                }, (resultList) => {
                       }).$promise;
            }

            loadCostBy() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CostBy
                }, (resultList) => {
                       }).$promise;
            }

            loadExchangeRateTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.RateType
                }, (resultList) => {
                       }).$promise;
            }

            loadSourceTypes(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CostingModelSourceType
                }, (resultList) => {
                       }).$promise;
            }

            loadFormulae(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CostingModelFormula
                }, (resultList) => {
                       }).$promise;
            }

            loadParameterTypes(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CostingModelParametersType
                }, (resultList) => {
                       }).$promise;
            }

            loadCostModelelement(searchText:string)
            {
                return this.costModelElementService.getDropdownList(this.costModel.Entity.Id).query(() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadCostDetail(sequenceNumber:number) : ng.IPromise<interfaces.applicationcore.IDropdownModel[]>{
                var deferre = this.$q.defer<interfaces.applicationcore.IDropdownModel[]>();

                var detailList : interfaces.applicationcore.IDropdownModel[] = [];
                _.forEach(this.costModel.CostModelDetailList, (value) => {
                    var dd = <interfaces.applicationcore.IDropdownModel>{
                        Id:  value.SequenceNumber,
                        Code: "",
                        Description: value.Code,
                        Display:  value.Code,
                        Selected: false
                    }
                    if (dd.Id < sequenceNumber)
                        detailList.push(dd);            
                });
                deferre.resolve(detailList);
                return deferre.promise;
            }

            
            loadCostDetailAll() : ng.IPromise<interfaces.applicationcore.IDropdownModel[]>{
                var deferre = this.$q.defer<interfaces.applicationcore.IDropdownModel[]>();

                var detailList : interfaces.applicationcore.IDropdownModel[] = [];
                _.forEach(this.costModel.CostModelDetailList, (value) => {
                    var dd = <interfaces.applicationcore.IDropdownModel>{
                        Id:  value.SequenceNumber,
                        Code: "",
                        Description: value.Code,
                        Display:  value.Code,
                        Selected: false
                    }
                    
                        detailList.push(dd);            
                });
                deferre.resolve(detailList);
                return deferre.promise;
            }

            registerApiSundryItemCosting(gridApi: uiGrid.IGridApi) {
                this.apiSundryItemCosting = gridApi;
            }

            removeCostDetailLine(index : number){
                   this.costModel.CostModelDetailList.splice(index,1);
            }

            radioChanged(index:number)
            {
               this.selectedRow = index;
            }

            setClickedRow(index:number)
            {
                this.selectedRow = index;
            }

            Add(){
                    var newRow = <interfaces.costing.ICostModelDetail>{
                        SequenceNumber :this.costModel.CostModelDetailList.length
                    };
                    this.costModel.CostModelDetailList.push(newRow);
            }

            MoveUp(){
                if (this.selectedRow>2)
                {
                    var storeCurrentRow = this.selectedRow;
                    var element = this.costModel.CostModelDetailList[this.selectedRow];
                    this.costModel.CostModelDetailList.splice(this.selectedRow, 1);
                    this.costModel.CostModelDetailList.splice(this.selectedRow-1, 0, element);
                    this.selectedRow = storeCurrentRow-1;
                }
            }

            MoveDown(){
                if (this.selectedRow>1)
                {
                    var storeCurrentRow = this.selectedRow;
                    var element = this.costModel.CostModelDetailList[this.selectedRow];
                    this.costModel.CostModelDetailList.splice(this.selectedRow, 1);
                    this.costModel.CostModelDetailList.splice(this.selectedRow+1, 0, element);
                    this.selectedRow = storeCurrentRow+1;
                }
            }

            AddSundryClick(){
                this.costModelSundry = undefined;
                this.addEditSundryCosting();             
            }

            editSundryClick(entity: interfaces.costing.ICostModelSundry){
                this.costModelSundry = entity;
                this.addEditSundryCosting();
            }

            
            addEditSundryCosting() {
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header">
                            <h3 class="modal-title" id="modal-title">Sundry Item Costing</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="sundry.add">
                            <form name="sundryadd">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <req></req>
                                                <label>Sundry Item</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-dropdown name="sundryItem" ng-model="addNewCtrl.costModelSundry.SundryItem" form="inputForm" load-data="addNewCtrl.loadSundryItems(searchText)" required="true" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                            </div>
                                        </div>
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Is Generated From Cost Model</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    <input type="checkbox"  
                                                        ng-model="addNewCtrl.costModelSundry.IsGeneratedFromCostModel" 
                                                        name="IsGeneratedFromCostModel">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <req ng-if="addNewCtrl.costModelSundry.IsGeneratedFromCostModel"></req>
                                                <opt ng-if="!addNewCtrl.costModelSundry.IsGeneratedFromCostModel"></opt>
                                                <label>Cost Model Element</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-dropdown name="costModelElement" ng-model="addNewCtrl.costModelSundry.CostModelElement" form="inputForm" load-data="addNewCtrl.loadCostModelelement()" required="false"></gts-dropdown>
                                            </div>
                                        </div>
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Create Sundry Item</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    <input type="checkbox"  
                                                        ng-model="addNewCtrl.costModelSundry.CreateSundryItem" 
                                                        name="CreateSundryItem">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Create Cost Invoice</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    <input type="checkbox"  
                                                        ng-model="addNewCtrl.costModelSundry.CreateCostInvoice" 
                                                        ng-change="addNewCtrl.createCostInvoiceChanged()"
                                                        name="CreateCostInvoice">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Cost Invoice Per Entity (Manifest)</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    <input type="checkbox"  ng-disabled="!addNewCtrl.costModelSundry.CreateCostInvoice"
                                                        ng-model="addNewCtrl.costModelSundry.CostInvoicePerEntity" 
                                                        name="CostInvoicePerEntity">
                                                </p>
                                            </div>
                                        </div>      
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Apportionate To Invoice</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    <input type="checkbox"  
                                                        ng-model="addNewCtrl.costModelSundry.ApportionateToInvoice" 
                                                        name="ApportionateToInvoice">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Apportionment Method</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-dropdown ng-disabled="!addNewCtrl.costModelSundry.ApportionateToInvoice" name="apportionmentMethod" ng-model="addNewCtrl.costModelSundry.ApportionmentMethod" form="inputForm" load-data="addNewCtrl.loadApportionmentMethods()" required="false"></gts-dropdown>
                                            </div>
                                        </div>  
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                            <req ng-if="addNewCtrl.costModelSundry.ApportionmentMethod.Id === 690"></req>
                                            <opt ng-if="!(addNewCtrl.costModelSundry.ApportionmentMethod.Id === 690)"></opt>
                                                <label>Unit Of Measure</label>
                                            </div>
                                            <div class="col-md-6">
                                                <gts-dropdown name="uom" ng-model="addNewCtrl.costModelSundry.UnitOfMeasure" form="inputForm" load-data="addNewCtrl.loadUoMs()" required="false"></gts-dropdown>
                                            </div>
                                        </div>        
                                        <div class="row vertical-align">
                                            <div class="col-md-5">
                                                <opt></opt>
                                                <label>Is Active</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    <input type="checkbox"  
                                                        ng-model="addNewCtrl.costModelSundry.IsActive" 
                                                        name="IsActive">
                                                </p>
                                            </div>
                                        </div>                                                                                                                                                                                                                                     
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button ng-disabled="!addNewCtrl.costModelSundry.SundryItem || (addNewCtrl.costModelSundry.IsGeneratedFromCostModel && !addNewCtrl.costModelSundry.CostModelElement) || (addNewCtrl.costModelSundry.ApportionmentMethod.Id === 690 && !addNewCtrl.costModelSundry.UnitOfMeasure)" class="btn btn-primary" type="button"  ng-click="addNewCtrl.add()">{{addNewCtrl.saveMessage}}</button>
                            <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                        </div>
                        `,
                    controller: class addNewCtrl {

                        messages: interfaces.applicationcore.IMessage[];

                        saveMessage: string = "Add New";

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private goodsDeclarationAmendmentRequestSettingService: interfaces.master.IGoodsDeclarationAmendmentRequestSettingService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,                        
                            private sundryItemService: interfaces.master.ISundryItemService,
                            private costModelElementService: interfaces.costing.ICostModelElementService,
                            private unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                            private costModelSundry : interfaces.costing.ICostModelSundry,
                            private entityId : number,
                            private typeId: number
                        ) {
                            /*this.selectedEntity = <interfaces.applicationcore.IDropdownModel>{
                                Id: $rootScope.Entity.Id,
                                Code: $rootScope.Entity.Code,
                                Description: $rootScope.Entity.Name,
                                Display: $rootScope.Entity.Code + " - " + $rootScope.Entity.Name,
                                Selected: true
                            };*/

                            
                            if (this.costModelSundry)
                            {
                                    this.saveMessage = "Save";
                            }
                            else
                            {
                                this.saveMessage = "Add New";

                                this.costModelSundry = <interfaces.costing.ICostModelSundry>{};
                                this.costModelSundry.Id = 0;
                                this.costModelSundry.IsActive = true;
                            }
                        }

                        createCostInvoiceChanged(){
                            if (!this.costModelSundry.CreateCostInvoice)
                            {
                                this.costModelSundry.CostInvoicePerEntity = false;
                            }
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        add() {
                            var saveItem =  this.costModelSundry;

                           this.$uibModalInstance.close(saveItem);
                        }

                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                () => {
                                }, (failureData) => {
                                }).$promise;
                        }

                        
                        loadSundryItems(searchText: string) {

                            /*222	ELC Costing = Imports
                            244	GLC Costing = Imports
                            413	ELC Pre-Costing = Imports
                            461	Export Costing = Exports
                            600	Export Consignment = Exports
                            733	Import Consignment = Imports*/

                            var isInbound : boolean = false;

                            switch(this.typeId) { 
                                case 222: { 
                                   isInbound = true;
                                   break; 
                                } 
                                case 244: { 
                                    isInbound = true;
                                   break; 
                                } 
                                case 413: { 
                                    isInbound = true;
                                   break; 
                                }   
                                case 461: { 
                                    isInbound = false;
                                   break; 
                                }     
                                case 600: { 
                                    isInbound = false;
                                   break; 
                                }      
                                case 733: { 
                                    isInbound = true;
                                   break; 
                                }                                                                                                                                    
                                default: { 
                                    isInbound = false;
                                   break; 
                                } 
                             } 


                            return this.sundryItemService.getDropdownList(this.entityId,isInbound, searchText).query(() => {

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        }

                        loadCostModelelement(searchText:string)
                        {
                            return this.costModelElementService.getDropdownList(this.entityId).query(() => {

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        }

                        loadApportionmentMethods()
                        {
                            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                                classificationId: Enum.ClassificationEnum.SundryCostingApportion
                            }, (resultList) => {
                                   }).$promise;
                        }

                        loadUoMs(){
                            return this.unitOfMeasureService.getUoMDropdown().query({ownerEntityId:this.entityId}, (resultList) => {
                                   }).$promise;
                        }



                    },
                    controllerAs: "addNewCtrl",
                    resolve: { costModelSundry : this.costModelSundry, entityId : this.costModel.Entity.Id, typeId: this.costModel.Type.Id
                    }
                }).result.then((result:interfaces.costing.ICostModelSundry) => {
                    if (result) {
                        if (result.Id===0)
                        {    
                            this.costModel.CostModelSundryList.push(<interfaces.costing.ICostModelSundry>result);
                        }
                        else
                        {

                        }

                        if (this.apiSundryItemCosting) {
                            this.$timeout(() => {
                                this.apiSundryItemCosting.rowEdit.setRowsDirty([<interfaces.costing.ICostModelSundry>result]);
                            });
                        }
                    }
                });
            }


            gvwSundryItemCosting: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerApiSundryItemCosting(gridApi); },               
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                                <button type="button"  ng-click="grid.appScope.CostModelUpdateCtrl.editSundryClick(row.entity)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                          
                            <button type="button"  ng-click="grid.appScope.CostModelUpdateCtrl.removeInlineSundry(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 96,
                        enableCellEditOnFocus: false
                    },                   
                    {
                        name: 'SundryItem',
                        displayName: '*Sundry Item',
                        field: 'SundryItem',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        enableSorting: false,
                        width: 170
                    }, 
                     {
                        name: "IsGeneratedFromCostModel",
                        displayName: "Is Generated from Cost Model",
                        enableFiltering: false,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,                        
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.IsGeneratedFromCostModel" >`,
                        width: 210
                    },         
                    {
                        name: 'CostModelElement',
                        displayName: 'Cost Model Element',
                        field: 'CostModelElement',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        enableSorting: false,
                        width: 150
                    },
                   {
                    name: "CreateCostInvoice",
                    displayName: "Create Cost Invoice",
                    enableFiltering: false,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,                        
                    cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.CreateCostInvoice" >`,
                    width: 150
                    }, 
                    {
                        name: "ApportionateToInvoice",
                        displayName: "Apportionate To Invoice",
                        enableFiltering: false,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,                        
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.ApportionateToInvoice" >`,
                        width: 180
                    }, 
                    {
                        name: 'ApportionmentMethod',
                        displayName: 'Apportionment Method',
                        field: 'ApportionmentMethod',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        enableSorting: false,
                        width: 170
                    } , 
                    {
                        name: 'UnitOfMeasure',
                        displayName: 'Unit Of Measure',
                        field: 'UnitOfMeasure',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        enableSorting: false,
                        width: 130
                    } ,
                    {
                     name: "Is Active",
                     displayName: "Is Active",
                     enableFiltering: false,
                     enableCellEdit: false,
                     enableCellEditOnFocus: false,                        
                     cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.IsActive" >`,
                     width: 85
                     }
                ]
            };


            removeInlineSundry(entity: interfaces.costing.ICostModelSundry){
                if (entity.Id === 0) {
                    _.remove(this.gvwSundryItemCosting.data, (o) => {
                        return o === entity;
                    });

                      if (this.apiSundryItemCosting) {
                    this.apiSundryItemCosting.rowEdit.setRowsClean([entity]);
                    }
                }
                else
                {
                    entity.IsActive = false;
                    this.apiSundryItemCosting.rowEdit.setRowsDirty([entity]);
                }
            }

        };

        angular.module("app").controller("CostModelUpdateCtrl", controllers.costing.CostModelUpdateCtrl);
    }
}
